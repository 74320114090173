var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TarStore } from './utils/tar-store';
/**
 * Remove the ./ or / from the start
 */
function normalizePath(path) {
    return path.replace(/^\.\//, '').replace(/^\//, '');
}
export class FileFetcher {
    constructor(manager) {
        this.manager = manager;
        this.tarStore = new TarStore();
    }
    getUrlFromFileProtocol(version) {
        return __awaiter(this, void 0, void 0, function* () {
            const tarLocation = normalizePath(version.replace(/^file:/, ''));
            const module = this.manager.transpiledModules['/' + tarLocation];
            if (!module) {
                throw new Error(`Could not find ${version} while resolving dependency`);
            }
            return module.module.code;
        });
    }
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this.getUrlFromFileProtocol(version);
            return this.tarStore.file(name, url, path);
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this.getUrlFromFileProtocol(version);
            return this.tarStore.meta(name, url);
        });
    }
}
