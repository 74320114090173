export default class HMR {
    constructor() {
        this.data = {};
        this.dirty = false;
        this.selfAccepted = false;
        this.invalidated = false;
    }
    callDisposeHandler() {
        if (this.disposeHandler) {
            this.data = {};
            this.disposeHandler(this.data);
            this.disposeHandler = undefined;
        }
    }
    callAcceptCallback() {
        if (this.callback) {
            this.callback();
        }
    }
    setAcceptCallback(callback) {
        this.callback = callback;
        this.setSelfAccepted(false);
    }
    setDisposeHandler(callback) {
        this.disposeHandler = callback;
    }
    setSelfAccepted(selfAccepted) {
        this.selfAccepted = selfAccepted;
        if (selfAccepted) {
            this.data = {};
        }
    }
    setType(type) {
        this.type = type;
    }
    setDirty(dirty) {
        this.dirty = dirty;
    }
    isDirty() {
        return this.dirty;
    }
    /**
     * Returns whether this module should reset the compilation of its parents
     */
    isHot() {
        return this.type === 'accept';
    }
    isDeclined(isEntry) {
        if (this.type === 'decline') {
            return true;
        }
        return !this.isHot() && isEntry;
    }
    /**
     * Setting the module to invalidated means that we MUST evaluate it again, which means
     * that we throw away its compilation and hmrConfig, and we're going to force a second evaluation
     * once this has been run.
     */
    setInvalidated(invalidated) {
        this.invalidated = invalidated;
    }
}
