export class Transpiler {
    constructor(name) {
        this.cacheable = true;
        this.name = name;
        this.HMREnabled = true;
    }
    initialize() { }
    dispose() { }
    cleanModule(loaderContext) { }
    /* eslint-enable */
    transpile(code, loaderContext) {
        return this.doTranspilation(code, loaderContext);
    }
    /**
     * Get custom info of the current transpiler, this is open for implementation
     * per transpiler
     */
    getTranspilerContext(manager) {
        return Promise.resolve({
            name: this.name,
            HMREnabled: this.HMREnabled,
            cacheable: this.cacheable,
        });
    }
}
