var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Transpiler } from '../..';
/**
 * This is a compatibility loader that acts as bridge between webpack loaders and sandpack
 * transpilers. It's a best effort on making webpack loaders work dynamically in Sandpack.
 */
export class WebpackTranspiler extends Transpiler {
    constructor(webpackLoader, evaluator) {
        super(webpackLoader);
        this.webpackLoader = evaluator.evaluate(webpackLoader);
    }
    doTranspilation(code, loaderContext) {
        return __awaiter(this, void 0, void 0, function* () {
            const loader = yield this.webpackLoader;
            const asyncFunc = () => (err, result) => {
                if (err) {
                    throw err;
                }
                return result;
            };
            // Todo; this whole conversion from http strings to buffers should be done on the CodeSandbox
            // side in a pre-transpiler. Ideally code like this should be unaware of the implementation
            // details of http strings, and just work with buffers instead.
            const codeIsHttp = code.startsWith('http');
            const webpackCode = codeIsHttp
                ? yield fetch(loaderContext._module.module.code)
                    .then(x => x.arrayBuffer())
                    .then(buffer => Buffer.from(buffer))
                : Buffer.from(code);
            const webpackLoaderContext = Object.assign(Object.assign({}, loaderContext), { async: asyncFunc });
            const webpackResult = yield loader.apply(webpackLoaderContext, [
                loader.raw ? webpackCode : webpackCode.toString('utf-8'),
            ]);
            return {
                transpiledCode: webpackResult,
            };
        });
    }
}
