var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithRetries } from './utils';
function normalize(files, fileObject = {}) {
    for (let i = 0; i < files.length; i += 1) {
        const { files: childFiles, type, path } = files[i];
        if (type === 'file') {
            const absolutePath = path;
            fileObject[absolutePath] = true; // eslint-disable-line no-param-reassign
        }
        if (childFiles) {
            normalize(childFiles, fileObject);
        }
    }
    return fileObject;
}
export class UnpkgFetcher {
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `https://unpkg.com/${name}@${version}${path}`;
            const result = yield fetchWithRetries(url).then(x => x.text());
            return result;
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `https://unpkg.com/${name}@${version}/?meta`;
            const result = yield fetchWithRetries(url).then(x => x.json());
            return normalize(result.files, {});
        });
    }
}
