var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithRetries } from './utils';
function normalize(files) {
    const normalizedResponse = {};
    const fileNames = Object.keys(files);
    for (let i = 0; i < fileNames.length; i += 1) {
        const fileName = fileNames[i];
        const absolutePath = '/' + fileName;
        normalizedResponse[absolutePath] = true; // eslint-disable-line no-param-reassign
    }
    return normalizedResponse;
}
export class GistFetcher {
    constructor() {
        this.fetchedGists = {};
    }
    getAPIURl(version) {
        const gistId = version.replace(/.*\//, '');
        return 'https://api.github.com/gists/' + gistId;
    }
    fetchGist(version) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.fetchedGists[version]) {
                return this.fetchedGists[version];
            }
            const url = this.getAPIURl(version);
            const result = yield fetchWithRetries(url).then(x => x.json());
            this.fetchedGists[version] = result;
            return result;
        });
    }
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.fetchGist(version);
            const file = result.files[path.replace(/^\//, '')];
            if (!file) {
                throw new Error('File not found: ' + path);
            }
            if (file.truncated) {
                return fetchWithRetries(file.raw_url).then(t => t.text());
            }
            return file.content;
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.fetchGist(version);
            return normalize(result.files);
        });
    }
}
