var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import delay from '@codesandbox/common/lib/utils/delay';
export function fetchWithRetries(url, retries = 2, requestInit) {
    return __awaiter(this, void 0, void 0, function* () {
        const doFetch = () => window.fetch(url, requestInit).then(x => {
            if (x.ok) {
                return x;
            }
            const error = new Error(`Could not fetch ${url}`);
            error.responseObject = x;
            throw error;
        });
        let lastTryTime = 0;
        for (let i = 0; i < retries; i++) {
            if (Date.now() - lastTryTime < 3000) {
                // Ensure that we at least wait 3s before retrying a request to prevent rate limits
                // eslint-disable-next-line
                yield delay(3000 - (Date.now() - lastTryTime));
            }
            try {
                lastTryTime = Date.now();
                // eslint-disable-next-line
                return yield doFetch();
            }
            catch (e) {
                console.error(e);
                if (i === retries - 1) {
                    throw e;
                }
            }
        }
        throw new Error('Could not fetch');
    });
}
