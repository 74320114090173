"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbsoluteDependencies = exports.getAbsoluteDependency = exports.isValidSemver = exports.isAbsoluteVersion = void 0;
const semver_1 = require("semver");
function fetchWithRetries(url) {
    return __awaiter(this, void 0, void 0, function* () {
        let err;
        for (let i = 0; i < 2; i++) {
            try {
                // eslint-disable-next-line
                return yield fetch(url).then(x => {
                    if (x.ok) {
                        return x.json();
                    }
                    throw new Error('Could not fetch ' + url);
                });
            }
            catch (e) {
                err = e;
            }
        }
        throw err;
    });
}
function fetchAllVersions(dep) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetchWithRetries(`https://data.jsdelivr.com/v1/package/npm/${dep}`);
    });
}
/** Resolves version range from unpkg, use this as a fallback when jsdelivr fails */
const resolveVersionFromUnpkg = (dep, version) => {
    return fetchWithRetries(`https://unpkg.com/${dep}@${encodeURIComponent(version)}/package.json`).then(x => x.version);
};
function getLatestVersion(dep, version) {
    return __awaiter(this, void 0, void 0, function* () {
        // No need to resolve absolute versions...
        if (isAbsoluteVersion(version)) {
            return version;
        }
        try {
            // If it is not an absolute version (e.g. a tag like `next`), we don't want to fetch
            // using JSDelivr, because JSDelivr caches the response for a long time. Because of this,
            // when a tag updates to a new version, people won't see that update for a long time.
            // Instead, we download all possible versions from JSDelivr, and we check those versions
            // to see what's the maximum satisfying version. The API call is cached for only 10s.
            const allVersions = yield fetchAllVersions(dep);
            return (allVersions.tags[version] || (0, semver_1.maxSatisfying)(allVersions.versions, version));
        }
        catch (e) {
            return resolveVersionFromUnpkg(dep, version);
        }
    });
}
function isAbsoluteVersion(version) {
    return /(^\d+\.\d+\.\d+(-.*)?$)|(.+\/.+)/.test(version);
}
exports.isAbsoluteVersion = isAbsoluteVersion;
function isValidSemver(version) {
    return Boolean((0, semver_1.valid)(version));
}
exports.isValidSemver = isValidSemver;
function getAbsoluteDependency(depName, depVersion) {
    return __awaiter(this, void 0, void 0, function* () {
        return {
            name: depName,
            version: yield getLatestVersion(depName, depVersion),
        };
    });
}
exports.getAbsoluteDependency = getAbsoluteDependency;
function getAbsoluteDependencies(dependencies) {
    return __awaiter(this, void 0, void 0, function* () {
        const nonAbsoluteDependencies = Object.keys(dependencies).filter(dep => !isAbsoluteVersion(dependencies[dep]));
        const newDependencies = Object.assign({}, dependencies);
        yield Promise.all(nonAbsoluteDependencies.map((dep) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { version } = yield getAbsoluteDependency(dep, newDependencies[dep]);
                newDependencies[dep] = version;
            }
            catch (e) {
                /* ignore */
            }
        })));
        return newDependencies;
    });
}
exports.getAbsoluteDependencies = getAbsoluteDependencies;
