var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _debug from '@codesandbox/common/lib/utils/debug';
import { clearMeasurements, measure, endMeasure, getMeasurements, getCumulativeMeasure, } from '@codesandbox/common/lib/utils/metrics';
const debug = _debug('cs:compiler:benchmarks');
const printTranspilationMeasurements = (results) => {
    debug(`Total ${results.total.toFixed(2)}ms`);
    debug(`  Transpiling ${results.transpile.toFixed(2)}ms`);
    debug(`  Resolving ${results.resolving.toFixed(2)}ms`);
    debug(`  Converting ES ${results.esConverting.toFixed(2)}ms`);
};
export function generateBenchmarkInterface(manager) {
    return {
        transpilation: (n = 10, path = '/src/index.js') => __awaiter(this, void 0, void 0, function* () {
            const module = yield manager.resolveModuleAsync({
                path,
            });
            const times = [];
            for (let i = 0; i < n; i++) {
                manager.clearTranspilationCache();
                manager.cachedPaths = {};
                clearMeasurements();
                measure('transpilation');
                // eslint-disable-next-line
                yield manager.transpileModules(module);
                const total = endMeasure('transpilation', { silent: true });
                times.push({
                    total,
                    resolving: getCumulativeMeasure('resolve', { silent: true }),
                    esConverting: getCumulativeMeasure('esconvert', { silent: true }),
                    transpile: getCumulativeMeasure('transpile', { silent: true }),
                });
            }
            const averageResults = times.reduce((result, entry) => ({
                total: result.total + entry.total / n,
                esConverting: result.esConverting + entry.esConverting / n,
                resolving: result.resolving + entry.resolving / n,
                transpile: result.transpile + entry.transpile / n,
            }), { total: 0, esConverting: 0, resolving: 0, transpile: 0 });
            printTranspilationMeasurements(averageResults);
        }),
        getLastTranspilationMeasurements() {
            printTranspilationMeasurements({
                total: getMeasurements().transpilation,
                resolving: getCumulativeMeasure('resolve', { silent: true }),
                esConverting: getCumulativeMeasure('esconvert', { silent: true }),
                transpile: getCumulativeMeasure('transpile', { silent: true }),
            });
        },
    };
}
