var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TarStore } from './utils/tar-store';
function getTarballUrl(url) {
    return url.replace(/\/_pkg.tgz$/, '');
}
export class CsbFetcher {
    constructor() {
        this.tarStore = new TarStore();
    }
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = getTarballUrl(version);
            return this.tarStore.file(name, url, path);
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = getTarballUrl(version);
            return this.tarStore.meta(name, url);
        });
    }
}
