const VERSION_RE = /(\d+)(\.\d+)?(\.\d+)?(-[^\s+]+)?/;
export function normalizeVersion(version) {
    const matches = version.match(VERSION_RE);
    if (!(matches === null || matches === void 0 ? void 0 : matches.length)) {
        // Invalid semver or a string like `latest` which is also an invalid semver but someone introduced it for some reason...
        return version;
    }
    return matches[0];
}
export function dependencyToQuery(name, version) {
    return encodeURIComponent(`${name}@${normalizeVersion(version)}`);
}
export default function dependenciesToQuery(dependencies) {
    return Object.keys(dependencies)
        .sort()
        .map(name => dependencyToQuery(name, dependencies[name]))
        .join('+');
}
