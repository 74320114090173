export default class ModuleWarning extends Error {
    constructor(module, warning) {
        super();
        this.name = 'ModuleWarning';
        this.path = warning.fileName || module.module.path;
        this.message = warning.message;
        this.warning = warning.message;
        this.lineNumber = warning.lineNumber;
        this.columnNumber = warning.columnNumber;
        this.severity = warning.severity || 'warning';
        this.source = warning.source;
    }
    serialize() {
        return {
            name: 'ModuleWarning',
            message: this.message,
            fileName: this.path,
            lineNumber: this.lineNumber,
            columnNumber: this.columnNumber,
            source: this.source,
            severity: this.severity,
        };
    }
}
