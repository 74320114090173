var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchWithRetries } from '../utils';
import { normalizeJSDelivr } from './utils';
/**
 * Converts urls like "https://github.com/user/repo.git" to "user/repo".
 */
const GH_RE = /^(((https:\/\/)|(git(\+(ssh|https))?:\/\/(.*@)?))(www\.)?github\.com(\/|:))?(([^\s#/]*)\/([^\s#/]*))(#(.*))?$/;
export function convertGitHubURLToVersion(ghUrl) {
    const result = ghUrl.match(GH_RE);
    if (result && result[10]) {
        const repo = result[10];
        const version = result[14];
        const cleanedRepo = repo.replace(/\.git$/, '');
        if (version) {
            return `${cleanedRepo}@${version}`;
        }
        return cleanedRepo;
    }
    return ghUrl;
}
export function isGithubDependency(ghUrl) {
    return GH_RE.test(ghUrl);
}
export class JSDelivrGHFetcher {
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `https://cdn.jsdelivr.net/gh/${convertGitHubURLToVersion(version)}${path}`;
            const result = yield fetchWithRetries(url).then(x => x.text());
            return result;
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            // Split the repo and requested version
            const [repo, repoVersion] = convertGitHubURLToVersion(version).split('@');
            // Fetch repo meta from GitHub
            // If the version is not specified, we use the default_branch from the repo meta
            let metaBranch = repoVersion;
            if (!metaBranch) {
                metaBranch = yield fetch(`https://api.github.com/repos/${repo}`)
                    .then(x => x.json())
                    .then(x => x.default_branch);
            }
            // We get the sha of the requested version
            const sha = yield fetch(`https://api.github.com/repos/${repo}/commits/${metaBranch}`)
                .then(x => x.json())
                .then(x => x.sha);
            const url = `https://data.jsdelivr.com/v1/package/gh/${repo}@${sha}/flat`;
            const result = yield fetchWithRetries(url).then(x => x.json());
            return normalizeJSDelivr(result.files, {});
        });
    }
}
