import { EMPTY_SHIM } from './constants';
export function* isFile(filepath, isFileFn) {
    if (filepath === EMPTY_SHIM) {
        return true;
    }
    return yield* isFileFn(filepath);
}
export function getParentDirectories(filepath, rootDir = '/') {
    const parts = filepath.split('/');
    const directories = [];
    while (parts.length > 0) {
        const directory = parts.join('/') || '/';
        // Test /foo vs /foo-something - /foo-something is not in rootDir
        if (directory.length < rootDir.length || !directory.startsWith(rootDir)) {
            break;
        }
        directories.push(directory);
        parts.pop();
    }
    return directories;
}
