export default {
  'stdlib/reasonReact': '@jaredly/reason-react/lib/js/src/ReasonReact.js',
  'stdlib/reasonReactOptimizedCreateClass':
    '@jaredly/reason-react/lib/js/src/ReasonReactOptimizedCreateClass.js',
  'stdlib/reactEventRe': '@jaredly/reason-react/lib/js/src/ReactEventRe.js',
  'stdlib/reactDOMServerRe':
    '@jaredly/reason-react/lib/js/src/ReactDOMServerRe.js',
  'stdlib/reactDOMRe': '@jaredly/reason-react/lib/js/src/ReactDOMRe.js',
  'stdlib/belt_MutableSet': '@jaredly/bs-core/lib/js/belt_MutableSet.js',
  'stdlib/belt_MutableStack': '@jaredly/bs-core/lib/js/belt_MutableStack.js',
  'stdlib/js_unsafe': '@jaredly/bs-core/lib/js/js_unsafe.js',
  'stdlib/caml_string': '@jaredly/bs-core/lib/js/caml_string.js',
  'stdlib/belt_internalMapString':
    '@jaredly/bs-core/lib/js/belt_internalMapString.js',
  'stdlib/belt_Id': '@jaredly/bs-core/lib/js/belt_Id.js',
  'stdlib/caml_module': '@jaredly/bs-core/lib/js/caml_module.js',
  'stdlib/js_internal': '@jaredly/bs-core/lib/js/js_internal.js',
  'stdlib/char': '@jaredly/bs-core/lib/js/char.js',
  'stdlib/js': '@jaredly/bs-core/lib/js/js.js',
  'stdlib/caml_gc': '@jaredly/bs-core/lib/js/caml_gc.js',
  'stdlib/js_null': '@jaredly/bs-core/lib/js/js_null.js',
  'stdlib/belt_HashMapString': '@jaredly/bs-core/lib/js/belt_HashMapString.js',
  'stdlib/int64': '@jaredly/bs-core/lib/js/int64.js',
  'stdlib/sort': '@jaredly/bs-core/lib/js/sort.js',
  'stdlib/belt_SortArrayInt': '@jaredly/bs-core/lib/js/belt_SortArrayInt.js',
  'stdlib/caml_basic': '@jaredly/bs-core/lib/js/caml_basic.js',
  'stdlib/js_result': '@jaredly/bs-core/lib/js/js_result.js',
  'stdlib/caml_utils': '@jaredly/bs-core/lib/js/caml_utils.js',
  'stdlib/js_promise': '@jaredly/bs-core/lib/js/js_promise.js',
  'stdlib/js_obj': '@jaredly/bs-core/lib/js/js_obj.js',
  'stdlib/belt_Map': '@jaredly/bs-core/lib/js/belt_Map.js',
  'stdlib/js_console': '@jaredly/bs-core/lib/js/js_console.js',
  'stdlib/caml_backtrace': '@jaredly/bs-core/lib/js/caml_backtrace.js',
  'stdlib/oo': '@jaredly/bs-core/lib/js/oo.js',
  'stdlib/caml_hash': '@jaredly/bs-core/lib/js/caml_hash.js',
  'stdlib/belt_SortArray': '@jaredly/bs-core/lib/js/belt_SortArray.js',
  'stdlib/random': '@jaredly/bs-core/lib/js/random.js',
  'stdlib/stream': '@jaredly/bs-core/lib/js/stream.js',
  'stdlib/belt_SetDict': '@jaredly/bs-core/lib/js/belt_SetDict.js',
  'stdlib/caml_int32': '@jaredly/bs-core/lib/js/caml_int32.js',
  'stdlib/format': '@jaredly/bs-core/lib/js/format.js',
  'stdlib/stringLabels': '@jaredly/bs-core/lib/js/stringLabels.js',
  'stdlib/belt_MutableSetString':
    '@jaredly/bs-core/lib/js/belt_MutableSetString.js',
  'stdlib/stdLabels': '@jaredly/bs-core/lib/js/stdLabels.js',
  'stdlib/caml_lexer': '@jaredly/bs-core/lib/js/caml_lexer.js',
  'stdlib/printf': '@jaredly/bs-core/lib/js/printf.js',
  'stdlib/unixLabels': '@jaredly/bs-core/lib/js/unixLabels.js',
  'stdlib/bs_obj': '@jaredly/bs-core/lib/js/bs_obj.js',
  'stdlib/moreLabels': '@jaredly/bs-core/lib/js/moreLabels.js',
  'stdlib/js_mapperRt': '@jaredly/bs-core/lib/js/js_mapperRt.js',
  'stdlib/belt_Set': '@jaredly/bs-core/lib/js/belt_Set.js',
  'stdlib/js_types': '@jaredly/bs-core/lib/js/js_types.js',
  'stdlib/caml_oo': '@jaredly/bs-core/lib/js/caml_oo.js',
  'stdlib/belt_internalMapInt':
    '@jaredly/bs-core/lib/js/belt_internalMapInt.js',
  'stdlib/pervasives': '@jaredly/bs-core/lib/js/pervasives.js',
  'stdlib/scanf': '@jaredly/bs-core/lib/js/scanf.js',
  'stdlib/std_exit': '@jaredly/bs-core/lib/js/std_exit.js',
  'stdlib/belt': '@jaredly/bs-core/lib/js/belt.js',
  'stdlib/caml_parser': '@jaredly/bs-core/lib/js/caml_parser.js',
  'stdlib/js_cast': '@jaredly/bs-core/lib/js/js_cast.js',
  'stdlib/belt_internalAVLset':
    '@jaredly/bs-core/lib/js/belt_internalAVLset.js',
  'stdlib/belt_HashSetInt': '@jaredly/bs-core/lib/js/belt_HashSetInt.js',
  'stdlib/belt_MutableMapString':
    '@jaredly/bs-core/lib/js/belt_MutableMapString.js',
  'stdlib/caml_array': '@jaredly/bs-core/lib/js/caml_array.js',
  'stdlib/belt_MutableSetInt': '@jaredly/bs-core/lib/js/belt_MutableSetInt.js',
  'stdlib/digest': '@jaredly/bs-core/lib/js/digest.js',
  'stdlib/marshal': '@jaredly/bs-core/lib/js/marshal.js',
  'stdlib/belt_List': '@jaredly/bs-core/lib/js/belt_List.js',
  'stdlib/caml_weak': '@jaredly/bs-core/lib/js/caml_weak.js',
  'stdlib/js_string': '@jaredly/bs-core/lib/js/js_string.js',
  'stdlib/callback': '@jaredly/bs-core/lib/js/callback.js',
  'stdlib/queue': '@jaredly/bs-core/lib/js/queue.js',
  'stdlib/camlinternalMod': '@jaredly/bs-core/lib/js/camlinternalMod.js',
  'stdlib/caml_bytes': '@jaredly/bs-core/lib/js/caml_bytes.js',
  'stdlib/camlinternalFormatBasics':
    '@jaredly/bs-core/lib/js/camlinternalFormatBasics.js',
  'stdlib/belt_MutableMap': '@jaredly/bs-core/lib/js/belt_MutableMap.js',
  'stdlib/belt_internalSetInt':
    '@jaredly/bs-core/lib/js/belt_internalSetInt.js',
  'stdlib/belt_Array': '@jaredly/bs-core/lib/js/belt_Array.js',
  'stdlib/caml_io': '@jaredly/bs-core/lib/js/caml_io.js',
  'stdlib/camlinternalLazy': '@jaredly/bs-core/lib/js/camlinternalLazy.js',
  'stdlib/gc': '@jaredly/bs-core/lib/js/gc.js',
  'stdlib/belt_HashSetString': '@jaredly/bs-core/lib/js/belt_HashSetString.js',
  'stdlib/belt_internalBuckets':
    '@jaredly/bs-core/lib/js/belt_internalBuckets.js',
  'stdlib/belt_HashMapInt': '@jaredly/bs-core/lib/js/belt_HashMapInt.js',
  'stdlib/parsing': '@jaredly/bs-core/lib/js/parsing.js',
  'stdlib/js_list': '@jaredly/bs-core/lib/js/js_list.js',
  'stdlib/belt_MutableMapInt': '@jaredly/bs-core/lib/js/belt_MutableMapInt.js',
  'stdlib/bs_string': '@jaredly/bs-core/lib/js/bs_string.js',
  'stdlib/caml_primitive': '@jaredly/bs-core/lib/js/caml_primitive.js',
  'stdlib/belt_internalSetString':
    '@jaredly/bs-core/lib/js/belt_internalSetString.js',
  'stdlib/js_boolean': '@jaredly/bs-core/lib/js/js_boolean.js',
  'stdlib/list': '@jaredly/bs-core/lib/js/list.js',
  'stdlib/js_float': '@jaredly/bs-core/lib/js/js_float.js',
  'stdlib/js_int': '@jaredly/bs-core/lib/js/js_int.js',
  'stdlib/unix': '@jaredly/bs-core/lib/js/unix.js',
  'stdlib/js_int64': '@jaredly/bs-core/lib/js/js_int64.js',
  'stdlib/set': '@jaredly/bs-core/lib/js/set.js',
  'stdlib/array': '@jaredly/bs-core/lib/js/array.js',
  'stdlib/arrayLabels': '@jaredly/bs-core/lib/js/arrayLabels.js',
  'stdlib/bytes': '@jaredly/bs-core/lib/js/bytes.js',
  'stdlib/caml_queue': '@jaredly/bs-core/lib/js/caml_queue.js',
  'stdlib/caml_missing_polyfill':
    '@jaredly/bs-core/lib/js/caml_missing_polyfill.js',
  'stdlib/lazy': '@jaredly/bs-core/lib/js/lazy.js',
  'stdlib/node': '@jaredly/bs-core/lib/js/node.js',
  'stdlib/string': '@jaredly/bs-core/lib/js/string.js',
  'stdlib/block': '@jaredly/bs-core/lib/js/block.js',
  'stdlib/js_math': '@jaredly/bs-core/lib/js/js_math.js',
  'stdlib/js_dict': '@jaredly/bs-core/lib/js/js_dict.js',
  'stdlib/arg': '@jaredly/bs-core/lib/js/arg.js',
  'stdlib/belt_MapInt': '@jaredly/bs-core/lib/js/belt_MapInt.js',
  'stdlib/belt_internalAVLtree':
    '@jaredly/bs-core/lib/js/belt_internalAVLtree.js',
  'stdlib/belt_HashMap': '@jaredly/bs-core/lib/js/belt_HashMap.js',
  'stdlib/js_global': '@jaredly/bs-core/lib/js/js_global.js',
  'stdlib/belt_MutableQueue': '@jaredly/bs-core/lib/js/belt_MutableQueue.js',
  'stdlib/js_null_undefined': '@jaredly/bs-core/lib/js/js_null_undefined.js',
  'stdlib/js_option': '@jaredly/bs-core/lib/js/js_option.js',
  'stdlib/caml_format': '@jaredly/bs-core/lib/js/caml_format.js',
  'stdlib/printexc': '@jaredly/bs-core/lib/js/printexc.js',
  'stdlib/genlex': '@jaredly/bs-core/lib/js/genlex.js',
  'stdlib/caml_exceptions': '@jaredly/bs-core/lib/js/caml_exceptions.js',
  'stdlib/weak': '@jaredly/bs-core/lib/js/weak.js',
  'stdlib/sys': '@jaredly/bs-core/lib/js/sys.js',
  'stdlib/filename': '@jaredly/bs-core/lib/js/filename.js',
  'stdlib/caml_obj': '@jaredly/bs-core/lib/js/caml_obj.js',
  'stdlib/belt_MapDict': '@jaredly/bs-core/lib/js/belt_MapDict.js',
  'stdlib/complex': '@jaredly/bs-core/lib/js/complex.js',
  'stdlib/js_exn': '@jaredly/bs-core/lib/js/js_exn.js',
  'stdlib/hashtbl': '@jaredly/bs-core/lib/js/hashtbl.js',
  'stdlib/belt_MapString': '@jaredly/bs-core/lib/js/belt_MapString.js',
  'stdlib/js_json': '@jaredly/bs-core/lib/js/js_json.js',
  'stdlib/js_vector': '@jaredly/bs-core/lib/js/js_vector.js',
  'stdlib/js_date': '@jaredly/bs-core/lib/js/js_date.js',
  'stdlib/belt_SetInt': '@jaredly/bs-core/lib/js/belt_SetInt.js',
  'stdlib/caml_sys': '@jaredly/bs-core/lib/js/caml_sys.js',
  'stdlib/js_nativeint': '@jaredly/bs-core/lib/js/js_nativeint.js',
  'stdlib/js_undefined': '@jaredly/bs-core/lib/js/js_undefined.js',
  'stdlib/caml_int64': '@jaredly/bs-core/lib/js/caml_int64.js',
  'stdlib/caml_md5': '@jaredly/bs-core/lib/js/caml_md5.js',
  'stdlib/caml_oo_curry': '@jaredly/bs-core/lib/js/caml_oo_curry.js',
  'stdlib/belt_SetString': '@jaredly/bs-core/lib/js/belt_SetString.js',
  'stdlib/caml_float': '@jaredly/bs-core/lib/js/caml_float.js',
  'stdlib/bigarray': '@jaredly/bs-core/lib/js/bigarray.js',
  'stdlib/caml_builtin_exceptions':
    '@jaredly/bs-core/lib/js/caml_builtin_exceptions.js',
  'stdlib/obj': '@jaredly/bs-core/lib/js/obj.js',
  'stdlib/js_typed_array': '@jaredly/bs-core/lib/js/js_typed_array.js',
  'stdlib/map': '@jaredly/bs-core/lib/js/map.js',
  'stdlib/int32': '@jaredly/bs-core/lib/js/int32.js',
  'stdlib/listLabels': '@jaredly/bs-core/lib/js/listLabels.js',
  'stdlib/belt_Range': '@jaredly/bs-core/lib/js/belt_Range.js',
  'stdlib/belt_Option': '@jaredly/bs-core/lib/js/belt_Option.js',
  'stdlib/js_array': '@jaredly/bs-core/lib/js/js_array.js',
  'stdlib/camlinternalFormat': '@jaredly/bs-core/lib/js/camlinternalFormat.js',
  'stdlib/nativeint': '@jaredly/bs-core/lib/js/nativeint.js',
  'stdlib/lexing': '@jaredly/bs-core/lib/js/lexing.js',
  'stdlib/js_primitive': '@jaredly/bs-core/lib/js/js_primitive.js',
  'stdlib/camlinternalOO': '@jaredly/bs-core/lib/js/camlinternalOO.js',
  'stdlib/belt_SortArrayString':
    '@jaredly/bs-core/lib/js/belt_SortArrayString.js',
  'stdlib/buffer': '@jaredly/bs-core/lib/js/buffer.js',
  'stdlib/bytesLabels': '@jaredly/bs-core/lib/js/bytesLabels.js',
  'stdlib/belt_internalBucketsType':
    '@jaredly/bs-core/lib/js/belt_internalBucketsType.js',
  'stdlib/curry': '@jaredly/bs-core/lib/js/curry.js',
  'stdlib/belt_internalSetBuckets':
    '@jaredly/bs-core/lib/js/belt_internalSetBuckets.js',
  'stdlib/belt_HashSet': '@jaredly/bs-core/lib/js/belt_HashSet.js',
  'stdlib/stack': '@jaredly/bs-core/lib/js/stack.js',
  'bs-platform': '@jaredly/bs-core',
};
