export class FileError extends Error {
}
export function buildWorkerError(error) {
    return {
        name: error.name,
        message: error.message,
        fileName: error.fileName,
        lineNumber: error.lineNumber,
        columnNumber: error.columnNumber,
    };
}
export function parseWorkerError(error) {
    const reconstructedError = new FileError(error.message);
    reconstructedError.name = error.name;
    reconstructedError.columnNumber = error.columnNumber;
    reconstructedError.fileName = error.fileName;
    reconstructedError.lineNumber = error.lineNumber;
    return reconstructedError;
}
