var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TarStore } from './utils/tar-store';
export function isTarDependency(uri) {
    try {
        const url = new URL(uri);
        return url.protocol === 'http:' || url.protocol === 'https:';
    }
    catch (err) {
        // do nothing...
    }
    return false;
}
export class TarFetcher {
    constructor() {
        this.tarStore = new TarStore();
    }
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.tarStore.file(name, version, path);
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.tarStore.meta(name, version);
        });
    }
}
