var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { downloadDependency, } from '../../fetch-npm-module';
import { fetchWithRetries } from '../utils';
import { normalizeJSDelivr } from './utils';
export class JSDelivrNPMFetcher {
    file(name, version, path) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `https://cdn.jsdelivr.net/npm/${name}@${version}${path}`;
            const result = yield fetchWithRetries(url).then(x => x.text());
            return result;
        });
    }
    meta(name, version) {
        return __awaiter(this, void 0, void 0, function* () {
            // if it's a tag it won't work, so we fetch latest version otherwise
            const latestVersion = /^\d/.test(version)
                ? version
                : JSON.parse((yield downloadDependency(name, version, '/package.json')).code).version;
            const url = `https://data.jsdelivr.com/v1/package/npm/${name}@${latestVersion}/flat`;
            const result = yield fetchWithRetries(url).then(x => x.json());
            return normalizeJSDelivr(result.files, {});
        });
    }
}
